exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-search-tsx": () => import("./../../../src/pages/content/search.tsx" /* webpackChunkName: "component---src-pages-content-search-tsx" */),
  "component---src-templates-blog-blog-author-index-tsx": () => import("./../../../src/templates/blog/BlogAuthorIndex.tsx" /* webpackChunkName: "component---src-templates-blog-blog-author-index-tsx" */),
  "component---src-templates-blog-blog-category-index-tsx": () => import("./../../../src/templates/blog/BlogCategoryIndex.tsx" /* webpackChunkName: "component---src-templates-blog-blog-category-index-tsx" */),
  "component---src-templates-blog-blog-index-tsx": () => import("./../../../src/templates/blog/BlogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-blog-index-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-content-collection-index-tsx": () => import("./../../../src/templates/content/CollectionIndex.tsx" /* webpackChunkName: "component---src-templates-content-collection-index-tsx" */),
  "component---src-templates-content-collection-tsx": () => import("./../../../src/templates/content/Collection.tsx" /* webpackChunkName: "component---src-templates-content-collection-tsx" */),
  "component---src-templates-content-detail-tsx": () => import("./../../../src/templates/content/Detail.tsx" /* webpackChunkName: "component---src-templates-content-detail-tsx" */),
  "component---src-templates-definition-glossary-index-tsx": () => import("./../../../src/templates/definition/GlossaryIndex.tsx" /* webpackChunkName: "component---src-templates-definition-glossary-index-tsx" */),
  "component---src-templates-definition-glossary-term-tsx": () => import("./../../../src/templates/definition/GlossaryTerm.tsx" /* webpackChunkName: "component---src-templates-definition-glossary-term-tsx" */),
  "component---src-templates-pages-page-tsx": () => import("./../../../src/templates/pages/Page.tsx" /* webpackChunkName: "component---src-templates-pages-page-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-nav-main-nav-tsx": () => import("./../../../src/components/nav/MainNav.tsx" /* webpackChunkName: "slice---src-components-nav-main-nav-tsx" */)
}

